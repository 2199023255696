import api from "./api";

// 账号查询
export function alexaVerify(params) {
  let url = "/v1/user/exist/verify";
  return api.post(url, params, true);
}

// 用户登录
export function alexaLogin(params) {
  let url = "/v1/user/authcode/request";
  return api.post(url, params, true);
}

//谷歌注销所开发登录接口，兼容66款APP
export function googleLogin(params) {
  let url = "/v1/user/google/cancel/googleLogin";
  return api.post(url, params, true);
}
//谷歌注销-登录前置获取密钥
export function googleGetSecretKey(params) {
  let url = "/v1/user/google/cancel/getSecretKey";
  return api.post(url, params, true);
}
//谷歌注销-发送验证码，兼容66款APP
export function googleSendVerifyCode(params) {
  let url = "/v1/user/google/cancel/sendVerifyCode";
  return api.post(url, params, true);
}
//谷歌注销-注销接口，兼容66款APP
export function googleCancel(params) {
  let url = "/v1/user/google/cancel/googleCancel";
  return api.post(url, params, true);
}
// 用户登录账号区域
export function alexaLoginPort(params) {
  let url = "v1/user/area/get";
  return api.post(url, params, true);
}

// 用户绑定
export function alexaBind(params) {
  let url =
    "https://pitangui.amazon.com/api/skill/link/M2AAAAAAAAAAAA?state=xyz&code=SplxlOBeZQQYbYS6WxSbIA";
  return api.post(url, params, true);
}

// 获取注册短信验证码
export function alexaSendCode(params) {
  let url = "/v1/user/verify/code/send";
  return api.post(url, params, true);
}

// 用户邮箱注册
export function alexaRegister(params) {
  let url = "/v1/user/register";
  return api.post(url, params, true);
}

// 校验验证码
export function alexaVerifyId(params) {
  let url = "/v1/user/auth/verifyId";
  return api.post(url, params, true);
}

// 用户更改密码或密码重设
export function alexaReset(params) {
  let url = "/v1/user/passwd/reset";
  return api.post(url, params, true);
}
